import React, { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useAppSelector } from '_src/store/hooks'

import { navs } from './navs'
import './index.less'

const PassMenu = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { activeUrl } = useAppSelector((store) => store.menu)
  const [visible, setVisible] = useState(false)

  const goLink = (item, type = 'pc') => {
    if (Object.prototype.hasOwnProperty.call(item, 'children')) return

    const { url = '' } = item
    type == 'mobile' && onClick()
    // 创建需要权限

    url && history.push(url)
  }

  const handleMenuClick = (item) => {
    const { key = '' } = item
    if (key && key.includes('/')) {
      goLink({ url: key }, 'mobile')
    }
  }

  const onClick = () => {
    setVisible(!visible)
  }

  useEffect(() => {}, [])
  return (
    <Fragment>
      <div className="mobile-menu-container">
        {navs(t, true).map((item, index) => (
          <div
            className={classNames('menu', activeUrl == item.url && 'menu_active')}
            key={index}
            onClick={() => {
              handleMenuClick(item)
            }}
          >
            {activeUrl == item.url ? (
              <img className="menu_icon" src={item.icon_active} />
            ) : (
              <img className="menu_icon" src={item.icon} />
            )}
            <div className="menu_title">{item.label}</div>
          </div>
        ))}
      </div>
    </Fragment>
  )
}

export default PassMenu
