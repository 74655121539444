const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   */
  // exact:是否匹配子路由
  // hideFooter:是否显示底部菜单
  // isRandomBox:{Number}是否现实随机色块 有数字就现实
  {
    path: '/home',
    component: () => import('../pages/home'),
    hideFooter: true,
    isRandomBox: 6,
  },
  // 搜索
  {
    path: '/search',
    component: () => import('../pages/search'),
  },
  // 发现
  {
    path: '/discover',
    component: () => import('../pages/discover'),
    hideFooter: true,
    exact: false,
    children: [
      // 作品详情
      {
        path: '/activityDetails/:id/:type/:activityValue',
        component: () => import('../pages/activityDetails'),
        hideFooter: false,
      },
      // 确认订单
      {
        path: '/orderinfo',
        component: () => import('../pages/orderInfo'),
      },
      // 什么是ip众筹
      {
        path: '/introduce',
        component: () => import('../pages/discover/introduce'),
      },
    ],
  },
  // 交易
  {
    path: '/trade',
    component: () => import('../pages/trade'),
    hideFooter: true,
    exact: true,
    children: [
      // 资产、卡包详情
      {
        path: '/assetDetails/index',
        component: () => import('../pages/assetDetails/index'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/assetList',
        component: () => import('../pages/assetDetails/assetList'),
        hideFooter: false,
        isRandomBox: 4,
      },
      {
        path: '/assetDetails/listingSet',
        component: () => import('../pages/assetDetails/listingSet'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/orderInfo',
        component: () => import('../pages/assetDetails/orderInfo'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/performance',
        component: () => import('../pages/assetDetails/performance'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/information',
        component: () => import('../pages/assetDetails/information'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/record',
        component: () => import('../pages/assetDetails/record'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/transferIndex',
        component: () => import('../pages/assetDetails/transfer/index'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/transferRecord',
        component: () => import('../pages/assetDetails/transfer/record'),
        hideFooter: false,
      },
      {
        path: '/assetDetails/authorDetails',
        component: () => import('../pages/assetDetails/authorDetails'),
        hideFooter: false,
      },
    ],
  },
  // 我的
  {
    path: '/mine',
    component: () => import('../pages/mine'),
    hideFooter: true,
    exact: true,
    isRandomBox: 3,
    children: [
      {
        path: '/agreement',
        component: () => import('../pages/mine/agreement'),
      },
      {
        path: '/smallChange/index',
        component: () => import('../pages/mine/smallChange/index'),
      },
      {
        path: '/smallChange/expenditureRecords',
        component: () => import('../pages/mine/smallChange/expenditureRecords'),
      },
      {
        path: '/smallChange/success',
        component: () => import('../pages/mine/smallChange/success'),
      },
      {
        path: '/smallChange/expenditureDetails',
        component: () => import('../pages/mine/smallChange/expenditureDetails'),
      },
      {
        path: '/airdropBox',
        component: () => import('../pages/mine/assetBackpack/airdropBox'),
      },
      {
        path: '/recharge/index',
        component: () => import('../pages/mine/recharge/index'),
      },
      {
        path: '/recharge/withdrawal',
        component: () => import('../pages/mine/recharge/withdrawal'),
      },
      {
        path: '/recharge/bankCard',
        component: () => import('../pages/mine/recharge/bankCard'),
      },
      {
        path: '/recharge/addBack',
        component: () => import('../pages/mine/recharge/addBack'),
      },
      {
        path: '/recharge/success',
        component: () => import('../pages/mine/recharge/success'),
      },
      {
        path: '/cashier',
        component: () => import('../pages/mine/cashier'),
      },
      {
        path: '/setup/index',
        component: () => import('../pages/mine/setup/index'),
      },
      {
        path: '/setup/about',
        component: () => import('../pages/mine/setup/about'),
      },
      {
        path: '/setup/author',
        component: () => import('../pages/mine/setup/author'),
      },
      {
        path: '/setup/platformSet',
        component: () => import('../pages/mine/setup/platformSet'),
      },
      {
        path: '/setup/publisher',
        component: () => import('../pages/mine/setup/publisher'),
      },
      {
        path: '/setup/success',
        component: () => import('../pages/mine/setup/success'),
      },
      {
        path: '/blindBox',
        component: () => import('../pages/mine/blindBox'),
        isRandomBox: 4,
      },
      {
        path: '/order/list:ins?',
        component: () => import('../pages/mine/order/list'),
      },
      {
        path: '/order/details',
        component: () => import('../pages/mine/order/details'),
      },
      {
        path: '/auth/index',
        component: () => import('../pages/mine/auth/index'),
      },
      {
        path: '/auth/success',
        component: () => import('../pages/mine/auth/success'),
      },
      {
        path: '/auth/account',
        component: () => import('../pages/mine/auth/account'),
      },
      {
        path: '/security/index',
        component: () => import('../pages/mine/security/index'),
      },
      {
        path: '/security/payPwd',
        component: () => import('../pages/mine/security/payPwd'),
      },
      {
        path: '/cardHolder/index',
        component: () => import('../pages/mine/cardHolder/index'),
        isRandomBox: 4,
      },
      {
        path: '/cardHolder/records',
        component: () => import('../pages/mine/cardHolder/records'),
        isRandomBox: 4,
      },
      {
        path: '/cardHolder/debris',
        component: () => import('../pages/mine/cardHolder/debris'),
        isRandomBox: 4,
      },
      {
        path: '/invitation/add',
        component: () => import('../pages/mine/invitation/add'),
      },
      {
        path: '/invitation/index',
        component: () => import('../pages/mine/invitation/index'),
      },
      {
        path: '/shortcut',
        component: () => import('../pages/mine/shortcut'),
      },
    ],
  },
  // 公告
  {
    path: '/notice',
    component: () => import('../pages/notice'),
    exact: true,
    children: [
      {
        path: '/notice/details',
        component: () => import('../pages/notice/details'),
      },
    ],
  },
  // 消息提醒
  {
    path: '/message',
    component: () => import('../pages/message'),
    hideFooter: false,
  },

  // 作者专区
  {
    path: '/authorsZone',
    component: () => import('../pages/authorsZone'),
  },
  // 版权租赁
  {
    path: '/copyrightLease',
    component: () => import('../pages/copyrightLease'),
    exact: true,
    children: [
      {
        path: '/copyrightLease/search',
        component: () => import('../pages/copyrightLease/search'),
      },
      {
        path: '/copyrightLease/history',
        component: () => import('../pages/copyrightLease/history'),
      },
      {
        path: '/copyrightLease/details',
        component: () => import('../pages/copyrightLease/details'),
      },
      {
        path: '/copyrightLease/myShare',
        component: () => import('../pages/copyrightLease/myShare'),
        exact: true,
        children: [
          {
            path: '/copyrightLease/myShare/receiveAward',
            component: () => import('../pages/copyrightLease/myShare/receiveAward'),
          },
          {
            path: '/copyrightLease/myShare/details',
            component: () => import('../pages/copyrightLease/myShare/details'),
          },
          {
            path: '/copyrightLease/myShare/status',
            component: () => import('../pages/copyrightLease/myShare/status'),
          },
        ],
      },
      {
        path: '/copyrightLease/rentIndetails',
        component: () => import('../pages/copyrightLease/rentIndetails'),
        exact: true,
        children: [
          {
            path: '/copyrightLease/rentIndetails/rentSuccess',
            component: () => import('../pages/copyrightLease/rentIndetails/rentSuccess'),
          },
        ],
      },
      {
        path: '/copyrightLease/help',
        component: () => import('../pages/copyrightLease/help'),
        exact: true,
        children: [
          {
            path: '/copyrightLease/help/details',
            component: () => import('../pages/copyrightLease/help/details'),
          },
        ],
      },
    ],
  },
  // AI盲盒
  {
    path: '/aiBlindBox',
    component: () => import('../pages/aiBlindBox'),
    exact: true,
    children: [
      {
        path: '/aiBlindBox/sendBlindBox',
        component: () => import('../pages/aiBlindBox/sendBlindBox'),
      },
      {
        path: '/aiBlindBox/open/:openBlindBox?',
        component: () => import('../pages/aiBlindBox/openBlindBox'),
      },
      {
        path: '/aiBlindBox/second/:secondCreation?',
        component: () => import('../pages/aiBlindBox/secondCreation'),
        exact: true,
      },
      {
        path: '/aiBlindBox/secondCreation/success',
        component: () => import('../pages/aiBlindBox/secondCreationSuccess'),
      },
      {
        path: '/aiBlindBox/secondCreation/finish',
        component: () => import('../pages/aiBlindBox/secondCreationFinish'),
      },
      {
        path: '/aiBlindBox/synthesis',
        component: () => import('../pages/aiBlindBox/synthesis'),
      },
      {
        path: '/aiBlindBox/record',
        component: () => import('../pages/aiBlindBox/synthesis/record'),
      },
    ],
  },
  // 发行商
  {
    path: '/publisher',
    component: () => import('../pages/publisher'),
  },
  // 榜单指数
  {
    path: '/listIndex',
    component: () => import('../pages/listIndex'),
  },
  {
    path: '/detailsInfo',
    component: () => import('../pages/detailsInfo'),
    exact: true,
    children: [
      {
        path: '/detailsInfo/crowdfundNotice',
        component: () => import('../pages/detailsInfo/noticeDetails'),
      },
    ],
  },
  // 登录注册
  {
    path: '/login',
    component: () => import('../pages/login/login'),
    exact: true,
    children: [
      {
        path: '/register/:invite?',
        component: () => import('../pages/login/register'),
      },
      {
        path: '/forget',
        component: () => import('../pages/login/forget'),
      },
      {
        path: '/change',
        component: () => import('../pages/login/change'),
      },
    ],
  },
  // 分享下载页
  {
    path: '/shareDownload',
    component: () => import('../pages/shareDownload'),
    exact: true,
    isRandomBox: 6,
  },
  {
    path: '/canvas',
    component: () => import('../pages/canvas'),
  },
  {
    path: '/payAgreement',
    component: () => import('../pages/mine/payAgreement'),
  },
  {
    path: '/gaming',
    component: () => import('../pages/gaming'),
  }
]
export default routes
