let timer = {}

window.addEventListener(
  'message',
  function (event) {
    // console.log('收到postMessage')
    // console.log(event)
    // console.log(event.data)
    // console.log(event.data.data)
    // console.log('收到postMessage:'+event.data.fun)
    if (event.data.fun) {
      window[event.data.fun](event.data)
    }
  },
  false
)

export function doBridge(fun, data) {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsToOcNoPrams) {
    let post = {
      fun: fun,
    }
    if (data) {
      post.data = data
    }
    window.webkit.messageHandlers.jsToOcNoPrams.postMessage(post)
  } else if (window.appBridge) {
    if (data) {
      window.appBridge[fun](data)
    } else {
      window.appBridge[fun]()
    }
  } else {
    var browserErr = sessionStorage.getItem('browserErr')
    if (browserErr == 1) {
      throw 13
    }
    let post = {
      fun: fun,
    }
    if (data) {
      post.data = data
    }
    // window.webkit.messageHandlers.jsToOcNoPrams.postMessage(post);
    parent.postMessage(post, '*')
  }
}

async function doBridgeCall(fun, data, json) {
  // console.log("postMessage")
  // console.log("fun:"+fun)
  // var that = this
  var prom = await new Promise(function (resolve, reject) {
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsToOcNoPrams) {
      let ii = index++
      window['call' + fun + '' + ii] = (data) => {
        // console.log("call")
        // console.log("result:")
        // console.log(data)
        resolve(data.data) //代码正常执行！
      }
      let post = {
        fun: fun,
      }
      if (data) {
        post.data = data
      }
      post.callback = 'call' + fun + '' + ii
      // console.log(post.callback)
      window.webkit.messageHandlers.jsToOcNoPrams.postMessage(post)

      //当异步代码执行成功时，我们才会调用resolve(...), 当异步代码失败时就会调用reject(...)
      //在本例中，我们使用setTimeout(...)来模拟异步代码，实际编码时可能是XHR请求或是HTML5的一些API方法.
      // setTimeout(function(){
      //     // resolve("成功!"); //代码正常执行！
      //     let info = {
      //       // appId:"testAppId",
      //       token: "",
      //       roleId: "7",
      //       serverId: "global_1",
      //       gameId: "123"
      //     }
      //     window["call"+fun](info)
      // }, 1000);
    } else if (window.appBridge) {
      let re
      if (data) {
        re = window.appBridge[fun](data)
      } else {
        re = window.appBridge[fun]()
      }
      if (json) {
        // console.log(re)
        re = JSON.parse(re)
      }
      // console.log("result:"+re)
      resolve(re)
    } else {
      var browserErr = sessionStorage.getItem('browserErr')
      // console.log("browserErr:"+browserErr)
      if (browserErr == 1) {
        throw 13
      }
      let ii = index++
      window['call' + fun + '' + ii] = (data) => {
        // console.log("call")
        // console.log("result:")
        // console.log(data)
        clearInterval(timer)
        resolve(data.data) //代码正常执行！
      }
      let post = {
        fun: fun,
      }
      if (data) {
        post.data = data
      }
      post.callback = 'call' + fun + '' + ii
      if (webTest == true) {
        timer = setInterval(() => {
          sessionStorage.setItem('browserErr', 1)
          showErrorAlert('未收到客户端响应')
          clearInterval(timer)
          reject('eeee')
        }, 3000)
      }
      parent.postMessage(post, '*')
    }
  })
  return prom
}

function checkBridge(name) {
  if (window.appBridge && window.appBridge[name]) {
    return true
  } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.jsToOcNoPrams) {
    return true
  }
  return false
}

export function checkApp() {
  return checkBridge('getAppInfo')
}

export async function getAppInfo() {
  // let re;
  if (checkBridge('getAppInfo')) {
    // re = window.appBridge.getAppInfo();
    // return JSON.parse(re);
    return await doBridgeCall('getAppInfo', '', true)
  } else {
    try {
      return await doBridgeCall('getAppInfo', '', true)
    } catch (e) {
      // console.log(e)
      let appInfo = {
        // appId:"testAppId",
        appId: process.env.VUE_APP_DEF_APPID,
        appKey: 'testAppKey',
        deviceId: '123',
      }
      return await appInfo
    }
  }
}

export function toBrowser(url) {
  if (checkBridge('toBrowser')) {
    // window.appBridge.toBrowser(url);
    doBridge('toBrowser', url)
  } else {
    try {
      doBridge('toBrowser', url)
    } catch (e) {
      // console.log(e)
      location.href = url
    }
  }
}

export function showErrorAlert(msg) {
  console.log(msg)
}

export function isTopLevelPage(type) {
  // type true：顶级页面，否则非顶级
  console.log(type, 'type')
  if (checkBridge('setHome')) {
    if (type) {
      doBridge('setHome', '1')
    } else {
      doBridge('setHome', '0')
    }
  }
}
