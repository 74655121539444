import { imgUrl } from '../../utils/staticUrl'

export const marketUrl = '/'
export const navs = (t) => {
  const defaultNavs = [
    {
      label: t('header.Home'),
      url: '/home',
      key: '/home',
      icon: imgUrl('aacCommon/tabicon_home.png'),
      icon_active: imgUrl('aacCommon/tabicon_home_on.png'),
    },
    {
      label: t('header.Discover'),
      url: '/discover',
      key: '/discover',
      icon: imgUrl('aacCommon/tabicon_discover.png'),
      icon_active: imgUrl('aacCommon/tabicon_discover_on.png'),
    },
    {
      label: t('header.Trade'),
      url: '/trade',
      key: '/trade',
      icon: imgUrl('aacCommon/tabicon_deal.png'),
      icon_active: imgUrl('aacCommon/tabicon_deal_on.png'),
    },
    {
      label: t('header.Mine'),
      url: '/mine',
      key: '/mine',
      icon: imgUrl('aacCommon/tabicon_mine.png'),
      icon_active: imgUrl('aacCommon/tabicon_mine_on.png'),
    },
  ]

  return defaultNavs
}
